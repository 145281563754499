import React, { useState, useEffect, useRef } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import useInView from "../hooks/useInView";

const CustomCarousel: React.FC<{}> = () => {
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [active, setActive] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<HTMLDivElement[]>([]);

  const change = () => {
    setActive((prevActive) => (prevActive === 0 ? 1 : 0));
  };

  useEffect(() => {
    const changeInterval = setInterval(change, 5000);
    return () => clearInterval(changeInterval);
  }, [active]);

  useEffect(() => {
    const container = containerRef.current;
    const activeItem = itemRefs.current[active];
    if (container && activeItem) {
      container.style.transition = "height 0.5s ease";
      container.style.height = "auto";
      const newHeight = activeItem.offsetHeight + "px";
      container.offsetHeight;
      container.style.height = newHeight;
    }
  }, [active, windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(innerWidth);
    };

    addEventListener("resize", handleResize);

    return () => {
      removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      const container = containerRef.current;
      const activeItem = itemRefs.current[active];
      if (container && activeItem) {
        container.style.transition = "height 0.5s ease";
        container.style.height = "auto";
        const newHeight = activeItem.offsetHeight + "px";
        container.offsetHeight; // Trigger reflow
        container.style.height = newHeight;
      }
    }
  }, [inView, active]);

  const imagesInfo: Queries.getCarouselImagesQuery = useStaticQuery(query);
  const images: IGatsbyImageData[] = imagesInfo.allFile.edges
    .map((edge) => edge.node.childImageSharp?.gatsbyImageData)
    .filter((image): image is IGatsbyImageData => image !== undefined);

  return (
    <div ref={ref}>
      <div className="relative" ref={containerRef} id="carousel-container">
        {images.map((image, index) => (
          <div
            ref={(el) => (itemRefs.current[index] = el!)}
            id={`carousetItem${index}`}
            key={index}
            className={`transition-opacity duration-500 ${
              index === active ? "opacity-100" : "opacity-0"
            } ${index === 1 ? "absolute top-0" : ""}`}
          >
            <GatsbyImage image={image} alt={`Carousel Image ${index + 1}`} />
            {index === 0 ? (
              <div className="absolute p-4 sm:p-6 top-0 sm:top-6 w-full text-center font-bold text-white text-3xl sm:text-4xl lg:text-[80px] lg:leading-relaxed">
                Embrace the Rain. This Season, Stay Dry and Shine Bright with
                Our Umbrellas.
              </div>
            ) : (
              <div className="absolute p-4 sm:p-6 top-0 sm:top-6 w-full text-center font-bold text-logored text-3xl sm:text-4xl lg:text-[80px] lg:leading-relaxed">
                Stay Stylish, Stay Protected: Umbrellas for Every Weather.
              </div>
            )}
          </div>
        ))}
        <div className="h-5 sm:h-10 w-full absolute bottom-0">
          <div className="w-1/12 xl:w-[5%] m-auto hover: cursor-pointer flex h-full items-center justify-evenly">
            <div
              className={`bg-white ${active === 0 ? "opacity-100" : "opacity-80 bg-grayish"} hover:opacity-100 rounded-full size-2 md:size-3`}
              onClick={() => setActive(0)}
            ></div>
            <div
              className={`bg-white ${active === 1 ? "opacity-100" : "opacity-80 bg-grayish"} hover:opacity-100 rounded-full size-2 md:size-3`}
              onClick={() => setActive(1)}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query getCarouselImages {
    allFile(filter: { sourceInstanceName: { eq: "carousel_images" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default CustomCarousel;
