import React, { useState, FormEvent } from "react";
import { toast } from "react-toastify";

interface EmailCollectorProps {
  collectorType: "popUp" | "form";
  postLink: string | URL | Request;
}
const EmailCollector: React.FC<EmailCollectorProps> = ({
  collectorType,
  postLink,
}) => {
  const [email, setEmail] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(postLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      // console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setEmail("");
      toast("Your Email was processed successfully.", {
        type: "success",
      });
    } catch (error) {
      toast("A problem occured. Try again later.", {
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`flex items-center ${collectorType == "form" ? "rounded-lg" : ""} p-2 w-full`}
    >
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        className="flex-1 px-3 py-2 border border-grayish rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-black text-[#000]"
        placeholder="Enter your email"
      />
      <button
        type="submit"
        className="ml-2 p-2 bg-[#000] text-white font-bold rounded-md hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-black box-border"
        disabled={isSubmitting}
      >
        <svg
          height="20px"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 495.003 495.003"
        >
          <path
            d="M492.431 32.443a8.024 8.024 0 00-5.44-2.125 7.89 7.89 0 00-3.5.816L7.905 264.422a14.154 14.154 0 00.153 25.472L133.4 349.618l250.62-205.99-219.565 220.786 156.145 74.4a14.115 14.115 0 006.084 1.376c1.768 0 3.519-.322 5.186-.977a14.188 14.188 0 007.97-7.956l154.596-390a7.968 7.968 0 00-2.005-8.814z"
            fill="white"
          />
        </svg>
      </button>
    </form>
  );
};

export default EmailCollector;
