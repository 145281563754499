import React from "react";
import { ToastContainer } from "react-toastify";
import { BannerBar, NavBar } from "../components";
import Footer from "../components/Footer";
import "react-toastify/dist/ReactToastify.css";
const Layout: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <div
      className="flex flex-col min-h-screen justify-between"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <BannerBar>
          <p>
            Catch us at the{" "}
            <a
              href="https://www.cantonfair.org.cn/en-US"
              target="_blank"
              rel="noopener noreferrer"
            >
              Canton Fair
            </a>
            ! Visit our booth to discover our latest range.
            {/* Visit us at stall no. <b>xxx</b> from <b>date 1 - date 2</b> */}
          </p>
        </BannerBar>
        <NavBar />
        {props.children}
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Layout;
