// Ignore all lsp erros in this file  as I don't have *.d.ts for google translate
import React, { useEffect } from "react";
const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
      },
      "google_translate_element",
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
    );
    document.body.appendChild(addScript);

    window.googleTranslateElementInit = googleTranslateElementInit;

    return () => {
      addScript.remove();
    };
  }, []);

  return <div id="google_translate_element" className="mx-auto"></div>;
};
export default GoogleTranslate;
