import { useEffect, useRef, useState } from "react";

interface UseInViewOptions extends IntersectionObserverInit {}

const useInView = (
  options?: UseInViewOptions,
): [React.RefObject<HTMLDivElement>, boolean] => {
  const [inView, setInView] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, inView];
};

export default useInView;
