import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useState } from "react";

interface ImageSwitcherProps {
  images: IGatsbyImageData[];
  product_code: string;
}
export const ImageSwitcher: React.FC<ImageSwitcherProps> = ({
  images,
  product_code,
}) => {
  const [index, setIndex] = useState(0);
  return (
    <div className="md:flex">
      {images.length > 1 && (
        <div className="flex mb-2 md:flex-col">
          {images.map((image, currIndex) => {
            const newWidth = Math.round((100 / image.height) * image.width);
            return (
              <div
                key={currIndex}
                className={`mr-2 md:mb-2 ${currIndex === index ? "border-2" : ""} overflow-clip box-border rounded-xl inline-block relative`}
                style={{ width: `${newWidth}px` }}
                onClick={() => setIndex(currIndex)}
              >
                <GatsbyImage
                  style={{ width: `${newWidth}px`, height: "100%" }}
                  image={image}
                  alt={product_code || "Product's Image"}
                />
                {index !== currIndex && (
                  <div className="w-full h-full bg-white opacity-50 absolute inset-0 hover:cursor-pointer hover:opacity-40" />
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className="relative inline-block overflow-clip box-border rounded-xl max-w-fit">
        {images && (
          <GatsbyImage
            className="block"
            image={images[index]!}
            alt={product_code || "Product's Image"}
          />
        )}
        <div className="w-full h-full absolute inset-0 hover:cursor-pointer" />
      </div>
    </div>
  );
};
