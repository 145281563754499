import React, { useState } from "react";
import { Link, GatsbyLinkProps } from "gatsby";
import { MainLogo, SearchBar } from ".";
interface CustomLinkProps extends GatsbyLinkProps<{}> {
  text: string;
}

const CustomLink: React.FC<CustomLinkProps> = (props) => {
  return (
    <Link to={props.to} activeClassName="underline">
      {props.text}
    </Link>
  );
};

const MobileButtons: React.FC<{
  toggleSearch: () => void;
  toggleMenu: () => void;
}> = (props) => {
  return (
    <div className="flex text-black">
      <button
        data-collapse-toggle="navbar-menu"
        type="button"
        className="inline-flex mr-2 items-center size-6 sm:size-10 justify-center text-sm rounded-lg md:hidden hover:bg-grayish focus:outline-none focus:ring-2 focus:ring-grayish"
        onClick={props.toggleMenu}
        aria-controls="navbar-search"
        aria-expanded="false"
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="size-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <button
        type="button"
        data-collapse-toggle="navbar-search"
        aria-controls="navbar-search"
        aria-expanded="false"
        className="md:hidden size-6 sm:size-10 hover:bg-grayish focus:outline-none focus:ring-4 focus:ring-grayish rounded-lg text-sm mr-1"
        onClick={props.toggleSearch}
      >
        <svg
          className="size-5 mx-auto"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
        <span className="sr-only">Search</span>
      </button>
    </div>
  );
};

const navbarOptions: {
  to: string;
  text: string;
}[] = [
  {
    to: "/products",
    text: "PRODUCTS",
  },
  {
    to: "/about",
    text: "ABOUT US",
  },
  {
    to: "/contact",
    text: "CONTACT",
  },
];
const NavBar = () => {
  const [searchState, setSearchState] = useState(false);
  const [menuOpenState, setMenuOpenState] = useState(false);

  function toggleMenu() {
    setMenuOpenState(!menuOpenState);
  }

  function toggleSearch() {
    setSearchState(!searchState);
  }
  return (
    <>
      <nav className="bg-white w-full h-fit box-border px-4 py-1 sticky top-0 z-50 shadow-xl">
        <div className="max-w-screen-xl m-auto">
          <div className="flex items-center justify-between">
            <div className="flex-grow justify-evenly items-stretch hidden md:flex">
              {navbarOptions.map((opt) => (
                <CustomLink key={opt.to} to={opt.to} text={opt.text} />
              ))}
            </div>
            <MainLogo iconTextFill="#222222" />
            <div
              className={`flex-grow hidden md:flex justify-center ${
                searchState ? "sm:hidden" : ""
              }`}
            >
              <SearchBar />
            </div>

            <MobileButtons
              toggleMenu={toggleMenu}
              toggleSearch={toggleSearch}
            />
          </div>
        </div>
        <div
          className={`mx-auto w-2/3 ${searchState ? "" : "hidden"} md:hidden mt-4`}
        >
          <SearchBar />
        </div>
        {/* Div to visualise 50% use */}
        {/*
        <div className="w-1/2 text-center bg-black text-white">
          Controller Div 50%
        </div>
        */}
      </nav>
      <div
        className={`bg-opacity-50 bg-[#d7d7d7] text-black ${menuOpenState ? "flex" : "hidden"} md:hidden flex-col w-full content-center flex-wrap`}
      >
        {navbarOptions.map((opt) => (
          <div
            key={opt.to}
            className="bg-opacity-50 p-3 hover:text-white w-full box-border hover:bg-black"
          >
            <Link to={opt.to} className="w-full" activeClassName="underline">
              <div className="mx-auto text-center">{opt.text}</div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default NavBar;
