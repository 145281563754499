import React from "react";

type BannerBarProps = {
  children: React.ReactNode;
};
const BannerBar: React.FC<BannerBarProps> = (props) => {
  return (
    <div className="w-full text-center font-bold content-center bg-logored text-white text-xs md:text-lg box-border px-1.5">
      {props.children}
    </div>
  );
};

export default BannerBar;
