import React from "react";
import { Truck, Art, Ship } from "../images/CustomIcons";

const items = [
  {
    icon: <Truck />,
    tagline: "Direct Factory",
    desc: "Our products reach you directly from our factories without any middlemen.",
  },
  {
    icon: <Art />,
    tagline: "Design your Own",
    desc: "You can customize the products in various designs and colors according to your preferences.",
  },
  {
    icon: <Ship />,
    tagline: "Bulk Shipping",
    desc: "Our products are shipped worldwide for large orders.",
  },
];

const HighLights = () => {
  return (
    <div className="max-w-screen-xl m-auto w-full p-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
      {items.map((item, index) => {
        return (
          <div
            className="w-full bg-white rounded-xl overflow-clip drop-shadow-md hover:drop-shadow-xl"
            key={index}
          >
            <div className="pt-2">
              <div className="m-auto w-1/2 xl:w-1/3 overflow-clip rounded-xl box-border">
                {item.icon}
              </div>
            </div>
            <div className="w-full p-2 pt-0 text-center font-bold text-xl md:text-2xl">
              {item.tagline}
            </div>
            <div className="w-full px-4 pb-3 text-center font-normal">
              {item.desc}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HighLights;
