import React, { useState, useEffect, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const SearchBar = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const queryRes: Queries.SearchProdsQuery = useStaticQuery(query);
  const [results, setResults] = useState<
    {
      readonly product_code: string | null;
    }[]
  >([]);
  const data = queryRes.allProductsJson.nodes;

  const [isInteracting, setIsInteracting] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setIsInteracting(true);
  };

  const handleMouseLeave = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => setIsInteracting(false), 2000));
  };

  const handleFocus = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setIsInteracting(true);
  };

  const handleBlur = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => setIsInteracting(false), 2000));
  };

  useEffect(() => {
    const handleScroll = () => setIsInteracting(false);
    addEventListener("scroll", handleScroll);
    return () => {
      removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = data.filter((node) =>
        node.product_code!.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  }, [searchQuery, data]);

  const handleResultClick = () => {
    setSearchQuery("");
    setResults([]);
    setIsInteracting(false);
  };

  return (
    <div>
      <div
        className="relative md:block"
        ref={divRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            className="size-4 text-black"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
          <span className="sr-only">Search icon</span>
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          className="block w-full p-2 pl-10 text-sm text-black border border-grayish rounded-lg bg-grayish focus:ring-black focus:border-black"
          placeholder="Search by Product Id"
        />
      </div>
      {isInteracting && divRef && divRef.current ? (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            width: divRef.current.offsetWidth,
          }}
          className="bg-grayish bg-opacity-70 text-center fixed z-[100]"
        >
          {searchQuery ? (
            results.length === 0 ? (
              <p>The Search term yields no results</p>
            ) : (
              <ul className="max-h-40 overflow-y-scroll overflow-x-hidden">
                {results.map(({ product_code }) => (
                  <li
                    key={`searchItem_${product_code}`}
                    className="notranslate hover:bg-black bg-opacity-95 hover:bg-opacity-15"
                    onClick={handleResultClick}
                  >
                    <Link to={`/products/${product_code!.replace(" ", "_")}`}>
                      <div>{product_code} </div>
                    </Link>
                  </li>
                ))}
              </ul>
            )
          ) : (
            <p>Search for something</p>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchBar;

export const query = graphql`
  query SearchProds {
    allProductsJson {
      nodes {
        product_code
      }
    }
  }
`;
