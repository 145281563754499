import React from "react";
import { Link } from "gatsby";
import BlackIcon from "../images/logo_black.png";
import WhiteIcon from "../images/logo_white.png";

interface MainLogoProps {
  iconTextFill: "#222222" | "white";
}
const MainLogo: React.FC<MainLogoProps> = (props) => {
  return (
    <div className="flex justify-items-start max-w-fit">
      <Link to="/" className="flex items-start">
        <div className="select-none h-10 w-auto">
          <img
            src={props.iconTextFill == "white" ? WhiteIcon : BlackIcon}
            className="h-full w-auto"
          />
        </div>
        {/* <Icon */}
        {/*   className="select-none font-semibold h-10 w-auto" */}
        {/*   iconTextColor={props.iconTextFill} */}
        {/* /> */}
        <span className="self-center text-2xl font-semibold whitespace-nowrap text-logored notranslate">
          ICONIC RAIN<sup>®</sup>
        </span>
      </Link>
    </div>
  );
};

export default MainLogo;
