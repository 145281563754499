import React from "react";

function Ship() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M9 4h5.446a1 1 0 01.848.47L18.75 10h4.408a.5.5 0 01.439.74l-3.937 7.217A4.992 4.992 0 0115 16a4.992 4.992 0 01-4 2 4.992 4.992 0 01-4-2 4.992 4.992 0 01-4.55 1.97l-1.236-6.791A1 1 0 012.198 10H3V5a1 1 0 011-1h1V1h4v3zm-4 6h11.392l-2.5-4H5v4zM3 20a5.978 5.978 0 004-1.528A5.978 5.978 0 0011 20a5.978 5.978 0 004-1.528A5.978 5.978 0 0019 20h2v2h-2a7.963 7.963 0 01-4-1.07A7.963 7.963 0 0111 22a7.963 7.963 0 01-4-1.07A7.963 7.963 0 013 22H1v-2h2z"></path>
    </svg>
  );
}

function Art() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      version="1.1"
      viewBox="0 0 325.04 325.04"
      xmlSpace="preserve"
    >
      <path d="M117.866 234.088c-2.956 14.532-4.875 21.558-16.092 22.458a5.999 5.999 0 00-1.815 11.524c.721.298 17.877 7.308 37.921 7.309h.007c13.968 0 25.95-3.386 35.612-10.063 11.45-7.912 19.344-20.294 23.541-36.788l-38.572-38.88c-32.597 5.276-37.215 27.788-40.602 44.44zM322.745 63.336c-1.037-1.046-2.887-2.293-5.806-2.293-3.423 0-12.516 0-67.74 46.992-25.509 21.706-54.92 48.559-78.314 71.41l36.603 36.894c24.061-25.009 52.129-56.355 74.451-83.258 14.096-16.986 24.935-31.002 32.216-41.657 9.644-14.113 13.868-22.769 8.59-28.088z"></path>
      <path d="M182.595 278.479c-12.387 8.56-27.429 12.899-44.716 12.899-22.753-.001-41.919-7.649-44.046-8.527a21.938 21.938 0 01-13.31-23.749 21.931 21.931 0 0119.341-18.446c.861-2.571 1.813-7.254 2.323-9.758 1.878-9.23 4.449-21.873 12.358-33.126 8.637-12.287 21.656-20.165 38.751-23.466 9.811-9.737 21.005-20.443 32.686-31.308-5.905-1.281-11.185-5.127-14.017-10.944-4.875-10.02-.623-22.073 9.484-26.895 10.133-4.834 22.287-.612 27.155 9.423a20 20 0 011.832 6.096c9.688-8.677 19.309-17.099 28.392-24.828l.16-.136c-10.209-19.536-24.849-36.845-42.687-50.098-25.614-19.031-56.114-29.096-88.2-29.104h-.025c-21.654 0-47.976 7.566-68.697 19.749C13.981 51.193-.005 71.163 0 92.49c.008 25.748 14.53 36.518 26.199 45.171 9.515 7.057 17.03 12.63 17.034 24.844.003 12.213-7.508 17.781-17.018 24.831C14.55 195.984.031 206.748.039 232.499c.006 21.324 14.001 41.299 39.406 56.244 20.736 12.198 47.072 19.78 68.73 19.786h.042c39.305 0 76.254-15.171 104.044-42.72 20.837-20.655 34.656-46.416 40.273-74.442-13.952 15.471-27.997 30.493-40.563 43.322-5.33 19.276-15.198 33.993-29.376 43.79zM111.054 77.103c2.498-10.871 13.4-17.657 24.354-15.167 10.939 2.478 17.793 13.282 15.313 24.138-2.499 10.844-13.407 17.631-24.362 15.154-10.948-2.464-17.805-13.281-15.305-24.125zm-66 37.049c-7.005-8.716-5.565-21.401 3.216-28.339 8.78-6.925 21.571-5.505 28.589 3.195 6.99 8.703 5.545 21.388-3.229 28.34-8.761 6.94-21.572 5.505-28.576-3.196zm10.692 133.016c-8.786-6.944-10.231-19.629-3.226-28.342 7-8.696 19.796-10.122 28.581-3.18 8.778 6.943 10.224 19.629 3.225 28.327-6.999 8.713-19.796 10.138-28.58 3.195z"></path>
    </svg>
  );
}

function Truck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      version="1.1"
      viewBox="0 0 489.999 489.999"
      xmlSpace="preserve"
    >
      <path d="M258.742 69.599H15.843v232.19h242.899V69.599zm-21.304 210.885H37.147V90.903h200.29v189.581zM317.724 148.408v72.802h105.502l-58.452-72.802h-47.05zm21.305 51.498v-30.194h15.531l24.249 30.194h-39.78z"></path>
      <path d="M468.415 345.437V211.749l-89.295-107.77h-99.313v241.458h-85.343c-8.26-19.017-27.216-32.355-49.235-32.355-22.018 0-40.975 13.338-49.235 32.355H0v21.304h91.573c.001 29.589 24.072 53.659 53.656 53.659 29.585 0 53.655-24.07 53.656-53.659H320.46c.001 29.589 24.072 53.659 53.656 53.659 29.585 0 53.655-24.07 53.656-53.659H490v-21.304h-21.585zm-323.186 53.659c-17.84 0-32.352-14.517-32.352-32.357 0-17.841 14.511-32.353 32.352-32.353s32.352 14.511 32.352 32.353c0 17.841-14.511 32.357-32.352 32.357zm228.887 0c-17.84 0-32.352-14.517-32.352-32.357 0-17.841 14.511-32.353 32.352-32.353 17.84 0 32.352 14.511 32.352 32.353-.001 17.841-14.512 32.357-32.352 32.357zm72.995-53.659h-23.76c-8.26-19.017-27.216-32.355-49.235-32.355-22.018 0-40.974 13.338-49.235 32.355h-23.77V125.283h68.001l77.998 94.143v126.011z"></path>
      <path d="M70.155 215.9h63.226v19.598c.45 12.139 13.628 12.35 17.434 8.218l60.387-49.802c2.869-2.178 7.229-9.996 0-16.436l-60.387-49.806c-4.941-4.551-16.639-3.217-17.434 8.218v19.603H70.155c-5.888 0-10.652 4.769-10.652 10.652v39.103c0 5.882 4.764 10.652 10.652 10.652zm10.652-39.103h63.226c5.889 0 10.652-4.77 10.652-10.652v-7.662l32.997 27.213-32.997 27.207v-7.655c0-5.883-4.764-10.652-10.652-10.652H80.807v-17.799z"></path>
    </svg>
  );
}

export { Ship, Truck, Art };
