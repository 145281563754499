import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
interface WeChatPosterProps {
  close: () => void;
}

const WeChatPoster: React.FC<WeChatPosterProps> = ({ close }) => {
  const imageInfo: Queries.WeChatQRImageQuery = useStaticQuery(query);
  const image: IGatsbyImageData = imageInfo.allFile.nodes[0]?.childImageSharp
    ?.gatsbyImageData as IGatsbyImageData;
  return (
    <div className="p-1">
      <div className="relative w-80 bg-white rounded-lg shadow-lg p-4">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-xl font-semibold text-center">WeChat QR Code</h1>
          <button
            onClick={close}
            className="text-black hover:text-opacity-75 focus:outline-none"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col items-center">
          <GatsbyImage image={image} alt="WeChat QR Code" />
          <p className="text-[#000] text-center">
            Scan the QR Code to reach us out on WeChat
          </p>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query WeChatQRImage {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, name: { eq: "WeChatQR" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
export default WeChatPoster;
